import Lottie from "lottie-react";
import SecHeadings from "./SecHeadings";
import coding1 from "../assets/json/coding1.json";

const About = () => {
  return (
    <section
      id="about"
      className="about py-10 md:py-16 bg-slate-100 bg-opacity-50 scroll-mt-12 dark:bg-slate-700"
    >
      <div className="sec-container">
        <div className="w-full md:w-1/2">
          <Lottie animationData={coding1} loop={true} className="graphic-img" />
        </div>
        <div className="w-full md:w-1/2">
          <SecHeadings
            heading="こんにちは"
          />
          <p className="mt-6 text-gray-700 text-lg dark:text-slate-300">
            <strong>サルマン　アル　ラハマン</strong>です、American International University-Bangladesh
            という大学でコンピュータサイエンスとエンジニアリングを卒業しました。私は今日本で仕事を探しています。私は23歳です。バングラデシュのダッカに住んでいます。
            今B-jetセンターで日本語を勉強しています。Typescript、Java、C#、Python、R、SQL、C、C++ができます。休みの日はいつも日本語をべんきよします、時々アニメをみます。
            趣味はゲムです。好きな食べ物はラーメンです。どうぞよろしくお願いいたします。
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
