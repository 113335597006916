const SecHeadings = ({ heading, subheading }) => {
  return (
    <>
      <h1 className="section-title text-lg">{heading}</h1>
      <h3 className="section-tagline">{subheading}</h3>
    </>
  );
};

export default SecHeadings;
